@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
.main-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  text-align: center;
  background-color: #D6ECFB;
  font-family: 'Ubuntu', sans-serif;
}

.logo
{
  width: 40%;
  max-width: 300px;
  height: auto;
}


.col 
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.tagline-1{
  font-size: 1.3em;
  color: #054C70;
  font-weight: 500;
}


.tagline-2{
  font-size: 1.5em;
  font-weight: 600;
  color: #242424;
}


.icon-row
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: auto;
}


.icon-row img {
  width: 30px;
  height: 30px;
  margin: 0.8rem;
  filter:  invert(40%) sepia(59%) saturate(3514%) hue-rotate(182deg) brightness(94%) contrast(104%);

  
}

.icon-row img:hover {
  width: 30px;
  height: 30px;
  margin: 0.8rem;
  filter:  invert(20%) sepia(59%) saturate(3514%) hue-rotate(182deg) brightness(94%) contrast(104%);

  
}

.copyright-text
{
  color: #535353;
}


@media only screen and (max-width: 600px) {
  .logo
{
  width: 70%;
  max-width: 400px;
  height: auto;
}

.tagline-2{
  margin: 20px;
}

}


